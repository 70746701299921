@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap");

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./customer_fonts/hlt/open-sans-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans'),
         url('./customer_fonts/hlt/open-sans-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./customer_fonts/hlt/open-sans-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./customer_fonts/hlt/open-sans-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./customer_fonts/hlt/open-sans-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  }
  /* open-sans-700 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./customer_fonts/hlt/open-sans-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans'),
         url('./customer_fonts/hlt/open-sans-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./customer_fonts/hlt/open-sans-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./customer_fonts/hlt/open-sans-v18-latin-700.woff') format('woff'), /* Modern Browsers */
         url('./customer_fonts/hlt/open-sans-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  }
  /* open-sans-800 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('./customer_fonts/hlt/open-sans-v18-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Open Sans'),
         url('./customer_fonts/hlt/open-sans-v18-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./customer_fonts/hlt/open-sans-v18-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('./customer_fonts/hlt/open-sans-v18-latin-800.woff') format('woff'), /* Modern Browsers */
         url('./customer_fonts/hlt/open-sans-v18-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  }
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap);
/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url(/static/media/open-sans-v18-latin-regular.5f4d4bc1.eot); /* IE9 Compat Modes */
    src: local('Open Sans'),
         url(/static/media/open-sans-v18-latin-regular.5f4d4bc1.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/open-sans-v18-latin-regular.33543c5c.woff2) format('woff2'), 
         url(/static/media/open-sans-v18-latin-regular.de0869e3.woff) format('woff'), 
         url(/static/media/open-sans-v18-latin-regular.049a929c.ttf) format('truetype'), /* Safari, Android, iOS */
  }
  /* open-sans-700 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url(/static/media/open-sans-v18-latin-700.fc0dbb2e.eot); /* IE9 Compat Modes */
    src: local('Open Sans'),
         url(/static/media/open-sans-v18-latin-700.fc0dbb2e.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/open-sans-v18-latin-700.0edb7628.woff2) format('woff2'), 
         url(/static/media/open-sans-v18-latin-700.1f85e92d.woff) format('woff'), 
         url(/static/media/open-sans-v18-latin-700.e5111cab.ttf) format('truetype'), /* Safari, Android, iOS */
  }
  /* open-sans-800 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url(/static/media/open-sans-v18-latin-800.b48d8065.eot); /* IE9 Compat Modes */
    src: local('Open Sans'),
         url(/static/media/open-sans-v18-latin-800.b48d8065.eot?#iefix) format('embedded-opentype'), 
         url(/static/media/open-sans-v18-latin-800.ab3e500e.woff2) format('woff2'), 
         url(/static/media/open-sans-v18-latin-800.05ebdbe1.woff) format('woff'), 
         url(/static/media/open-sans-v18-latin-800.67a97b77.ttf) format('truetype'), /* Safari, Android, iOS */
  }
@font-face {
    font-family: "HelveticaMedium";
    font-weight: 400;
    src: local("helvetica"), local("helvetica medium"), url(/static/media/helvetica-65-medium.89d32411.otf) format("opentype");
}
@font-face {
    font-family: "HelveticaBold";
    font-weight: 700;
    src: local("helvetica"), local("helvetica bold"), url(/static/media/helvetica-77-bold-condensed.c9d86a79.otf) format("opentype");
}
@font-face {
    font-family: "Helvetica";
    font-weight: 400;
    src: local("helvetica"), local("helvetica medium"), url(/static/media/helvetica-65-medium.89d32411.otf) format("opentype");
}
@font-face {
    font-family: "Helvetica";
    font-weight: 700;
    src: local("helvetica"), local("helvetica bold"), url(/static/media/helvetica-77-bold-condensed.c9d86a79.otf) format("opentype");
}
@font-face {
  font-family: "helvetica-neue-lt-std-75-bold";
  font-weight: 700;
  src: url(/static/media/helvetica-neue-lt-std-75-bold.9ac7e2ff.otf) format("opentype");
}

@font-face {
    font-family: "HelveticaMedium";
    font-weight: 400;
    src: local("helvetica"), local("helvetica medium"), url("./customer_fonts/vl/helvetica-65-medium.otf") format("opentype");
}
@font-face {
    font-family: "HelveticaBold";
    font-weight: 700;
    src: local("helvetica"), local("helvetica bold"), url("./customer_fonts/vl/helvetica-77-bold-condensed.otf") format("opentype");
}
@font-face {
    font-family: "Helvetica";
    font-weight: 400;
    src: local("helvetica"), local("helvetica medium"), url("./customer_fonts/vl/helvetica-65-medium.otf") format("opentype");
}
@font-face {
    font-family: "Helvetica";
    font-weight: 700;
    src: local("helvetica"), local("helvetica bold"), url("./customer_fonts/vl/helvetica-77-bold-condensed.otf") format("opentype");
}
@font-face {
  font-family: "helvetica-neue-lt-std-75-bold";
  font-weight: 700;
  src: url("./customer_fonts/vl/helvetica-neue-lt-std-75-bold.otf") format("opentype");
}